<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script lang="ts">
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'App',

  components: {
    IonApp,
    IonRouterOutlet
  },
});
</script>

<style>
.modal_controller_class {
  --height: 100%;
  --max-height: 100%;
  --max-width: 100%;
  --min-height: 100%;
  --min-width: 100%;
  --width: 100%;
}

.common_center_modal_class{
  --height: 35rem;
  --max-height: 35rem;
  --min-height: 28rem;
  --max-width: 26rem;
  --min-width: 26rem;
  --width: 26rem;
  --overflow: visible;
  --backdrop-opacity: 0.6 !important;
  .ion-page {
    overflow: visible;
  }

  &::part(content) {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}

/*提示 见 openModal文件 openMsgToast*/
.common_toast_controller_class {
  --background: transparent;
  --box-shadow: none;
  text-align: center;
  --start: auto;
  --end: auto;
  --width: 100%;
  --height: 50%;

  &::part(container) {
    position: relative;
    z-index: 11;
    display: flex;
    overflow: visible;
  }

  &::part(message) {
    padding: 2VW 3VW;
    background-color: rgba(96, 98, 102, 0.8);
    border-radius: 2VW;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: 3px;
  }
}

</style>
