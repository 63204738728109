import store from './store';
import http from './http';
import myConfig from './config';

export default {

    //获取OEM配置
    getOEMResources(callback: any) {
        const params = {hostname: myConfig.config.hostname}
        http.post(`/oem/getOEMResource`, {params}).then(res => {
            if (res.data.success) {
                store.setOEMConfig(res.data.dataMap);
                if (callback) {
                    callback(res.data.dataMap);
                }
            }
        })
    },

    getResources() {
        const oemConfig = store.getOEMConfig();
        if (oemConfig) {
            document.title = oemConfig.OEM_DETAIL_KEY_BRAND_NAME;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.getElementById("shortcut").href = store.getOEMUrl() + "/admin/favicon.ico";
        }
    },

}
